export const paragraphsYes = [
    "Tak, to koniec Eldorado w branży IT w Polsce. Rosnące koszty pracy odstraszają inwestorów.",
    "Firmy coraz częściej przenoszą swoje operacje do innych krajów. To oznacza koniec złotych czasów.",
    "Spadająca dynamika wzrostu w polskim IT wskazuje na koniec Eldorado.",
    "Rosnące wymagania finansowe specjalistów IT w Polsce mogą zniechęcać inwestorów. To koniec złotych czasów.",
    "Polska traci konkurencyjność na rynku IT. To znak, że Eldorado się kończy.",
    "Wzrost wynagrodzeń i zmniejszająca się liczba projektów wskazują na koniec złotych czasów.",
    "Firmy szukają tańszych lokalizacji dla swoich operacji IT. Polska przestaje być atrakcyjna.",
    "Zmiany na globalnym rynku pracy oznaczają koniec Eldorado w polskim IT.",
    "Rosnące koszty zatrudnienia sygnalizują zmierzch złotych czasów dla polskiej branży IT.",
    "Firmy przenoszą swoje operacje do innych krajów. Polska traci na atrakcyjności.",
    "Rosnące wymagania finansowe i konkurencja z innych rynków oznaczają koniec Eldorado.",
    "Złote czasy dla polskiego IT mogą się kończyć z powodu rosnących kosztów pracy.",
    "Polska branża IT stoi przed wyzwaniami, które oznaczają koniec złotych czasów.",
    "Rosnące koszty i spadająca dynamika wzrostu wskazują na koniec Eldorado w polskim IT.",
    "Firmy zaczynają szukać tańszych lokalizacji. To koniec złotych czasów dla polskiego IT.",
    "Rosnące wynagrodzenia i zmniejszająca się liczba projektów sygnalizują zmierzch Eldorado.",
    "Zmiany w globalnym rynku pracy oznaczają koniec złotych czasów dla polskiej branży IT.",
    "Polska traci konkurencyjność na rynku IT, co oznacza koniec Eldorado.",
    "Rosnące koszty zatrudnienia zniechęcają inwestorów. To koniec złotych czasów.",
    "Spadająca dynamika wzrostu i rosnące koszty wskazują na koniec Eldorado w polskim IT.",
    "Tak, to koniec Eldorado w branży IT w Polsce. Rosnące koszty pracy odstraszają inwestorów, a firmy coraz częściej przenoszą swoje operacje do innych krajów, gdzie koszty są niższe.",
    "Firmy z branży IT, które kiedyś chętnie inwestowały w Polsce, teraz szukają tańszych lokalizacji. Spadająca dynamika wzrostu i rosnące wynagrodzenia sprawiają, że Polska traci na atrakcyjności.",
    "Wzrost kosztów pracy i zmniejszająca się liczba projektów w polskim IT wskazują na koniec Eldorado. Firmy zaczynają dostrzegać, że inwestycje w Polsce nie są już tak opłacalne jak kiedyś.",
    "Rosnące wymagania finansowe specjalistów IT w Polsce mogą zniechęcać inwestorów. To prowadzi do przenoszenia operacji do innych krajów, co oznacza koniec złotych czasów.",
    "Polska traci konkurencyjność na rynku IT z powodu rosnących kosztów pracy. Firmy szukają tańszych alternatyw, co może oznaczać zmierzch Eldorado.",
    "Wzrost wynagrodzeń i zmniejszająca się liczba projektów wskazują na koniec złotych czasów. Firmy przenoszą swoje operacje do krajów o niższych kosztach.",
    "Zmiany na globalnym rynku pracy oznaczają koniec Eldorado w polskim IT. Rosnące koszty zatrudnienia skłaniają firmy do szukania bardziej opłacalnych lokalizacji.",
    "Rosnące koszty zatrudnienia sygnalizują zmierzch złotych czasów dla polskiej branży IT. Firmy zaczynają przenosić swoje operacje do innych krajów.",
    "Polska przestaje być atrakcyjna dla firm IT. Rosnące wymagania finansowe i konkurencja z innych rynków oznaczają koniec Eldorado.",
    "Złote czasy dla polskiego IT mogą się kończyć z powodu rosnących kosztów pracy. Firmy szukają tańszych lokalizacji, aby utrzymać konkurencyjność.",
    "Polska branża IT stoi przed wyzwaniami, które oznaczają koniec złotych czasów. Rosnące koszty i zmniejszająca się liczba projektów skłaniają firmy do przenoszenia operacji.",
    "Rosnące koszty i spadająca dynamika wzrostu wskazują na koniec Eldorado w polskim IT. Firmy coraz częściej wybierają inne kraje o niższych kosztach.",
    "Firmy zaczynają szukać tańszych lokalizacji. Polska traci na atrakcyjności, co oznacza koniec złotych czasów dla polskiego IT.",
    "Rosnące wynagrodzenia i zmniejszająca się liczba projektów sygnalizują zmierzch Eldorado. Firmy przenoszą swoje operacje do innych krajów.",
    "Zmiany w globalnym rynku pracy oznaczają koniec złotych czasów dla polskiej branży IT. Polska traci konkurencyjność z powodu rosnących kosztów pracy.",
    "Polska traci konkurencyjność na rynku IT, co oznacza koniec Eldorado. Firmy przenoszą swoje operacje do krajów o niższych kosztach.",
    "Rosnące koszty zatrudnienia zniechęcają inwestorów. To koniec złotych czasów, ponieważ firmy szukają bardziej opłacalnych lokalizacji.",
    "Spadająca dynamika wzrostu i rosnące koszty wskazują na koniec Eldorado w polskim IT. Firmy przenoszą swoje operacje do innych krajów.",
    "Polska traci na atrakcyjności jako miejsce dla inwestycji IT. Rosnące koszty pracy i zmniejszająca się liczba projektów wskazują na koniec złotych czasów.",
    "Rosnące koszty i zmniejszająca się liczba projektów sygnalizują zmierzch Eldorado w polskim IT. Firmy przenoszą swoje operacje do krajów o niższych kosztach."
];

export const paragraphsNo = [
    "Nie, to nie koniec Eldorado w branży IT w Polsce. Mimo wyzwań, sektor wciąż rośnie.",
    "Polska branża IT wciąż przyciąga inwestorów. Złote czasy jeszcze się nie skończyły.",
    "Mimo rosnących kosztów, Polska nadal jest atrakcyjna dla firm IT.",
    "Polska branża IT przechodzi transformację, ale nie oznacza to końca Eldorado.",
    "Inwestycje w nowe technologie i innowacje wskazują, że złote czasy trwają.",
    "Polska wciąż przyciąga talenty IT, co oznacza, że Eldorado trwa.",
    "Mimo wyzwań, sektor IT w Polsce nadal rośnie i się rozwija.",
    "Inwestorzy wciąż widzą potencjał w polskiej branży IT. Złote czasy trwają.",
    "Polska branża IT jest w fazie przejściowej, ale nie oznacza to końca Eldorado.",
    "Innowacje i rozwój technologiczny w Polsce wskazują, że złote czasy trwają.",
    "Polska wciąż przyciąga inwestycje w sektor IT. To nie koniec Eldorado.",
    "Mimo rosnących kosztów, Polska pozostaje atrakcyjnym rynkiem dla firm IT.",
    "Transformacja rynku IT w Polsce nie oznacza końca złotych czasów.",
    "Polska branża IT wciąż ma duży potencjał wzrostu. Złote czasy jeszcze się nie skończyły.",
    "Inwestorzy nadal widzą potencjał w polskiej branży IT. Eldorado trwa.",
    "Polska wciąż przyciąga talenty i inwestycje w sektor IT.",
    "Rozwój technologiczny i innowacje w Polsce wskazują, że złote czasy trwają.",
    "Polska pozostaje konkurencyjna na rynku IT. To nie koniec Eldorado.",
    "Sektor IT w Polsce wciąż rośnie, mimo wyzwań. Złote czasy trwają.",
    "Inwestycje i rozwój technologiczny w Polsce wskazują, że Eldorado trwa.",
    "Nie, to nie koniec Eldorado w branży IT w Polsce. Mimo wyzwań, sektor wciąż rośnie, a inwestycje w nowe technologie i innowacje przyciągają firmy z całego świata.",
    "Polska branża IT wciąż przyciąga inwestorów. Złote czasy jeszcze się nie skończyły, ponieważ Polska oferuje wykwalifikowanych specjalistów i konkurencyjne warunki pracy.",
    "Mimo rosnących kosztów, Polska nadal jest atrakcyjna dla firm IT. Inwestorzy widzą potencjał w rozwijającym się rynku i dostępności wykwalifikowanych kadr.",
    "Polska branża IT przechodzi transformację, ale nie oznacza to końca Eldorado. Wzrost inwestycji w nowe technologie i rozwój start-upów wskazują na dalszy rozwój.",
    "Inwestycje w nowe technologie i innowacje wskazują, że złote czasy trwają. Polska wciąż przyciąga firmy z całego świata dzięki swojej elastyczności i adaptacyjności.",
    "Polska wciąż przyciąga talenty IT, co oznacza, że Eldorado trwa. Wykwalifikowani specjaliści i rosnący rynek technologiczny przyciągają inwestorów.",
    "Mimo wyzwań, sektor IT w Polsce nadal rośnie i się rozwija. Inwestycje w nowe technologie i rozwijające się start-upy świadczą o dalszym potencjale.",
    "Inwestorzy wciąż widzą potencjał w polskiej branży IT. Złote czasy trwają, ponieważ Polska oferuje innowacyjne rozwiązania i wykwalifikowaną kadrę.",
    "Polska branża IT jest w fazie przejściowej, ale nie oznacza to końca Eldorado. Wzrost inwestycji i rozwój technologiczny wskazują na dalszy rozwój.",
    "Innowacje i rozwój technologiczny w Polsce wskazują, że złote czasy trwają. Polska wciąż przyciąga inwestorów swoją dynamiką i potencjałem.",
    "Polska wciąż przyciąga inwestycje w sektor IT. To nie koniec Eldorado, ponieważ rynek jest elastyczny i adaptacyjny.",
    "Mimo rosnących kosztów, Polska pozostaje atrakcyjnym rynkiem dla firm IT. Inwestorzy dostrzegają potencjał w rozwijającym się rynku technologicznym.",
    "Transformacja rynku IT w Polsce nie oznacza końca złotych czasów. Wzrost inwestycji w nowe technologie i rozwój start-upów świadczą o dalszym potencjale.",
    "Polska branża IT wciąż ma duży potencjał wzrostu. Złote czasy jeszcze się nie skończyły, a inwestycje w nowe technologie nadal przyciągają firmy.",
    "Inwestorzy nadal widzą potencjał w polskiej branży IT. Eldorado trwa, ponieważ Polska oferuje innowacyjne rozwiązania i wykwalifikowaną kadrę.",
    "Polska wciąż przyciąga talenty i inwestycje w sektor IT. Złote czasy trwają, ponieważ rynek technologiczny w Polsce jest dynamiczny i rośnie.",
    "Rozwój technologiczny i innowacje w Polsce wskazują, że złote czasy trwają. Polska nadal jest atrakcyjna dla firm IT dzięki swojej elastyczności i adaptacyjności.",
    "Polska pozostaje konkurencyjna na rynku IT. To nie koniec Eldorado, ponieważ inwestorzy wciąż widzą potencjał w rozwijającym się rynku.",
    "Sektor IT w Polsce wciąż rośnie, mimo wyzwań. Złote czasy trwają, ponieważ Polska oferuje innowacyjne rozwiązania i wykwalifikowaną kadrę.",
    "Inwestycje i rozwój technologiczny w Polsce wskazują, że Eldorado trwa. Polska wciąż przyciąga firmy z całego świata dzięki swojej elastyczności i adaptacyjności."
];